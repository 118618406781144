import { FormConfig } from '../../design/Forms/interfaces';
import { invalidFileType, tooManyFiles } from '../../utils/fileValidation';
import {
    composeValidators,
    isDateLessThanToday,
    minLength,
    required,
} from '../../utils/validation';

export const userProfileFormConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'half',
            name: 'firstName',
            type: 'input',
            label: 'First Name',
            isRequired: true,
            validation: composeValidators(required),
        },
        renderProps: {
            placeholder: 'Enter First Name',
            isDisabled: true,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'lastName',
            type: 'input',
            label: 'Last Name',
            isRequired: true,
            validation: composeValidators(required),
        },
        renderProps: {
            placeholder: 'Enter Last Name',
            isDisabled: true,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'primaryEmail',
            type: 'input',
            label: 'Primary Email Address',
            isRequired: true,
            validation: composeValidators(required),
        },
        renderProps: {
            placeholder: 'Enter Last Primary Email Address',
            isDisabled: true,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'secondaryEmail',
            type: 'input',
            label: 'Secondary Email Address',
        },
        renderProps: {
            placeholder: 'Enter Last Secondary Email Address',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'phoneNumber',
            type: 'input',
            label: 'Phone Number',
            isRequired: true,
            validation: composeValidators(required),
        },
        renderProps: {
            placeholder: 'Enter Phone Number',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'organization',
            type: 'input',
            label: 'Organization',
        },
        renderProps: {
            placeholder: 'Organization',
            isDisabled: true,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'role',
            type: 'input',
            label: 'Role',
        },
        renderProps: {
            placeholder: 'Enter Role Type',
            isDisabled: true,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'profilePhoto',
            type: 'fileupload',
            label: 'Profile Photo',
            validation: composeValidators(
                tooManyFiles(1),
                invalidFileType(['image/png', 'image/jpg', 'image/jpeg', 'image/bmp']),
            ),
        },
        renderProps: {
            filesAccepted: ['.png', '.jpg', '.jpeg', '.bmp'],
            placeholder: 'Or select from your computer',
            helperText: 'Drag your profile picture here',
            multipleFiles: false,
            isDisabled: false,
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'socialMedia.linkedin',
            type: 'input',
            label: 'LinkedIn Profile',
        },
        renderProps: {
            placeholder: 'Enter LinkedIn Profile',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'socialMedia.twitter',
            type: 'input',
            label: 'Twitter / X Profile',
        },
        renderProps: {
            placeholder: 'Enter Twitter Profile',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'socialMedia.facebook',
            type: 'input',
            label: 'Facebook Profile',
        },
        renderProps: {
            placeholder: 'Enter Facebook Profile',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            // eslint-disable-next-line max-lines
            name: 'socialMedia.instagram',
            type: 'input',
            label: 'Instagram',
            validation: composeValidators(minLength(2)),
        },
        renderProps: {
            placeholder: 'Enter Instagram Profile',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'keyDates.birthDate',
            type: 'datepicker',
            label: 'Birthday',
            isRequired: false,
            validation: composeValidators(
                isDateLessThanToday('Date must be equal or less than today'),
            ),
        },
        renderProps: {
            placeholder: 'Enter Birthday',
            isDisabled: false,
            size: 'small',
            format: 'MMM dd, yyyy',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: 'keyDates.relationshipStartDate',
            type: 'datepicker',
            label: 'Relationship Start Date',
        },
        renderProps: {
            placeholder: 'Enter Relationship Start Date',
            isDisabled: false,
            size: 'small',
            format: 'MMM dd, yyyy',
        },
    },
];

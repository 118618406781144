import React from 'react';

import { InputLabel } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import theme from '../../styles/theme';
import { LabelColor } from '../FormFields/GenericFormInput';

export interface Label {
    label: string;
    isRequired?: boolean;
    labelColor?: LabelColor;
}

export default function FieldLabel({ label, isRequired, labelColor = 'regular' }: Label) {
    const { classes } = useStyles();

    const labelColorMap = {
        white: theme.palette.neutralWhite,
        regular: theme.palette.neutral.neutral10,
    };

    return (
        <InputLabel
            required={isRequired}
            sx={{ color: labelColorMap[labelColor] }}
            className={classes.label}>
            {label}
        </InputLabel>
    );
}
const useStyles = makeStyles()(() => ({
    label: {
        fontSize: 12,
        fontWeight: 500,
    },
}));

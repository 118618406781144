import React from 'react';

import { Box, Typography, useTheme } from '@mui/material';

import { ReactComponent as BirthdayIcon } from '../../assets/icons/birthday_icon.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar_with_heart_icon.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/communications_icon.svg';
import { ReactComponent as LocationIcon } from '../../assets/icons/pin_location_icon.svg';
import { ReactComponent as ViewIcon } from '../../assets/icons/view_icon.svg';

interface InfoWithLabel {
    text?: string;
    type?: 'email' | 'phone' | 'location' | 'date' | 'birthday' | 'views';
    //TODO rethink interface when we have more types than 7
}

const IconMapping = {
    email: EmailIcon,
    phone: EmailIcon,
    location: LocationIcon,
    date: CalendarIcon,
    birthday: BirthdayIcon,
    views: ViewIcon,
};
export default function PersonalInfoWithLabel({ text, type }: InfoWithLabel) {
    const theme = useTheme();
    const IconComponent = type && IconMapping[type];
    return (
        <Box display={'flex'} columnGap={2} alignItems={'center'}>
            {IconComponent && <IconComponent style={{ fill: theme.palette.neutral.neutral4 }} />}
            <Typography variant="font12" fontWeight={'medium'} color={'text.light'}>
                {text}
            </Typography>
        </Box>
    );
}

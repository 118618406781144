import { useEffect, useState } from 'react';

function useAvatar(profilePhoto?: string, fileType?: string) {
    const [avatarUrl, setAvatarUrl] = useState<string>('');

    useEffect(() => {
        const url = profilePhoto && fileType && createBlobUrl(profilePhoto, fileType);
        url && setAvatarUrl(url);

        return () => {
            url && URL.revokeObjectURL(url);
        };
    }, [profilePhoto, fileType]);

    function createBlobUrl(byteStream: string, fileType: string) {
        const blob = base64ToBlobImage(byteStream, fileType);
        return URL.createObjectURL(blob);
    }

    function createFile(byteStream: string, fileName: string, fileType: string) {
        const blob = base64ToBlobImage(byteStream, fileType);
        return [new File([blob], fileName, { type: fileType })];
    }

    return { avatarUrl, createBlobUrl, createFile };
}

function base64ToBlobImage(byteStream: string, fileType: string) {
    const binaryData = byteStream && atob(byteStream);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
    }

    return new Blob([uint8Array], { type: fileType });
}

export default useAvatar;

import { Box } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { ReactComponent as StarIcon } from '../../../assets/icons/primary_star_icon.svg';
import { ReactComponent as UserCheck } from '../../../assets/icons/user_check_icon.svg';
import { ReactComponent as UserX } from '../../../assets/icons/user_x_icon.svg';
import UserAvatar from '../../../design/Avatar/UserAvatar';
import theme from '../../../styles/theme';
import { typeOfAdvocacy } from './PrimaryContactMainInfo';

interface OwnProps {
    name: string;
    avatar?: string;
    advocacy?: typeOfAdvocacy[];
    isDeceased: boolean;
    isPrimaryContact?: boolean;
}

export default function AvatarWithBorder({
    name,
    avatar,
    advocacy,
    isDeceased,
    isPrimaryContact,
}: OwnProps) {
    const { classes } = useStyles();

    const errorStatus = advocacy?.includes('DO_NOT_CONTACT');
    const successStatus = advocacy?.length && !errorStatus;
    const primaryContact = isPrimaryContact;

    function getIcon() {
        if (errorStatus || isDeceased)
            return (
                <div className={classes.icon}>
                    <UserX />
                </div>
            );
        if (primaryContact)
            return (
                <div className={classes.starIcon}>
                    <StarIcon />
                </div>
            );
        if (successStatus)
            return (
                <div className={classes.icon}>
                    <UserCheck />
                </div>
            );
    }
    function getBorderClass() {
        if (errorStatus || isDeceased) return classes.errorBorder;
        if (primaryContact) return classes.primaryContact;
        if (successStatus) return classes.successBorder;
    }

    return (
        <>
            <Box className={getBorderClass()}>
                <UserAvatar name={name} avatar={avatar} size={'xlarge'} />
            </Box>
            {getIcon()}
        </>
    );
}

const useStyles = makeStyles()(() => ({
    primaryContact: {
        position: 'relative',
        border: `3px solid ${theme.palette.primaryBlue}`,
        borderRadius: '50%',
    },
    errorBorder: {
        position: 'relative',
        border: `3px solid ${theme.palette.statusRedTint}`,
        borderRadius: '50%',
    },
    successBorder: {
        position: 'relative',
        border: `2px solid ${theme.palette.limeGreen.limeGreenShade2}`,
        borderRadius: '50%',
    },
    icon: {
        position: 'relative',
        top: '-20px',
        left: '45px',
    },
    starIcon: {
        position: 'relative',
        bottom: '90px',
        left: '45px',
    },
}));

import { useState } from 'react';

export function usePagination(initialPage = 1, initialItemsPerPage = 25) {
    const [currentPage, setCurrentPage] = useState<number>(initialPage);
    const [itemsPerPage, setItemsPerPage] = useState<number>(initialItemsPerPage);
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handleItemsPerPageChange = (items: number) => {
        setItemsPerPage(items);
    };

    return {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    };
}

import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
interface OwnProps {
    onClick: () => void;
    isHidden?: boolean;
    size?: 'small' | 'medium' | 'large';
}

export default function CloseButton({ onClick, isHidden, size = 'medium' }: OwnProps) {
    if (isHidden) return null;

    return (
        <IconButton onClick={onClick} size={'small'}>
            <CloseIcon
                sx={{
                    fontSize: (() => {
                        if (size === 'small') return 10;
                        if (size === 'medium') return 20;
                        return 24;
                    })(),
                }}
            />
        </IconButton>
    );
}

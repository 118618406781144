import React, { ReactElement } from 'react';

import { Box, Grid, IconButton, Modal as MUIModal, Typography } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { ReactComponent as CloseIcon } from '../assets/icons/close_icon.svg';
import Loader from '../design/BaseLoader';

interface OwnProps {
    isShowing: boolean;
    onClose: () => void;
    header?: string;
    children: ReactElement;
    isLoading?: boolean;
    size?: 'xSmall' | 'small' | 'smallMedium' | 'medium' | 'large';
    height?: 'xSmall' | 'small' | 'medium' | 'large';
}

export default function BaseModal({
    isShowing,
    header,
    onClose,
    children,
    isLoading,
    size = 'large',
    height,
}: OwnProps) {
    const { classes } = useStyles();

    return (
        <MUIModal
            disableRestoreFocus
            open={isShowing}
            onClose={onClose}
            className={classes.modalWrapper}>
            <Grid
                className={classes.modal}
                sx={{
                    width:
                        (size === 'xSmall' && '20vw') ||
                        (size === 'small' && '40vw') ||
                        (size === 'smallMedium' && '50vw') ||
                        (size === 'medium' && '60vw') ||
                        (size === 'large' && '90vw') ||
                        'auto',
                    height:
                        (height === 'xSmall' && '20vh') ||
                        (height === 'small' && '40vh') ||
                        (height === 'medium' && '60vh') ||
                        (height === 'large' && '90vh') ||
                        'auto',
                }}>
                <Grid className={classes.modalHeader}>
                    <Box display={'flex'} maxWidth={'70%'}>
                        {header && (
                            <Typography noWrap className={classes.modalHeaderTitle}>
                                {header}
                            </Typography>
                        )}
                    </Box>
                    <IconButton
                        className={classes.modalCloseButton}
                        onClick={() => onClose()}
                        size="large">
                        <CloseIcon />
                    </IconButton>
                </Grid>
                {isLoading ? (
                    <Grid
                        container
                        sx={{ height: '70vh' }}
                        alignItems={'center'}
                        justifyContent={'center'}
                        flexDirection={'column'}>
                        <Loader />
                    </Grid>
                ) : (
                    children
                )}
            </Grid>
        </MUIModal>
    );
}

const useStyles = makeStyles()((theme) => ({
    modalWrapper: {
        display: 'flex',
        position: 'fixed',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1102,
        width: '100%',
    },
    modal: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxHeight: '90vh',
        minHeight: 250,
        zIndex: 100,
        background: theme.palette.background.default,
        position: 'relative',
        borderRadius: 12,
    },
    modalHeader: {
        height: 70,
        position: 'sticky',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '24px',
        borderRadius: '12px 12px 0 0',
        backgroundColor: theme.palette.neutralWhite,
    },
    modalHeaderTitle: {
        fontWeight: 600,
        fontSize: '14px',
        fontFamily: 'Montserrat',
        color: theme.palette.neutral.neutral10,
    },
    modalCloseButton: {
        padding: 0,
        color: theme.palette.background.default,
        cursor: 'pointer',
        border: 'none',
        width: '18px',
        height: '18px',
    },
}));

import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { createNumberMask } from 'text-mask-addons';

export const currencyMask = createNumberMask({
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 9,
    allowNegative: false,
    allowLeadingZeroes: false,
});
export const numberMask = createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 26,
    allowNegative: false,
    allowLeadingZeroes: false,
});

export const formatCurrency = (value?: string | number) => {
    return value
        ? new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
          }).format(+value)
        : '$0.00';
};
export const formatNumber = (value?: string | number) => {
    return value
        ? new Intl.NumberFormat('en-US', {
              maximumFractionDigits: 2,
          }).format(+value)
        : '0';
};

export const phoneMaskForString = (value: string): string | undefined => {
    const phoneNumber = parsePhoneNumberFromString(`+${value}`, 'US');
    if (!phoneNumber) {
        console.error(`Invalid phone number: ${value}`);
        return undefined;
    }
    const internationalFormat = phoneNumber.format('INTERNATIONAL');
    const nationalFormat = phoneNumber.format('NATIONAL');
    const countryCode = phoneNumber.countryCallingCode;
    const country = phoneNumber.country;

    if (countryCode) {
        return country === 'US' ? `+1 ${nationalFormat}` : ` ${internationalFormat}`;
    }
    return undefined;
};

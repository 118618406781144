import { Notification } from '../../features/Notifications/NotificationCard';
import { HTTP_METHOD } from '../auth/http';
import { ENDPOINTS } from '../endpoints';
import { getListProvidesTags } from '../generic';
import { OAKENApi } from '../OAKENApi';
import { NOTIFICATIONS } from '../tags';

export const notificationAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        getNotificationsSettings: builder.query({
            query: (organizationId) => {
                return {
                    url:
                        process.env.REACT_APP_NOTIFICATION_API_HOST +
                        ENDPOINTS.notifications.settings +
                        `/${organizationId}`,
                    method: HTTP_METHOD.GET,
                };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, NOTIFICATIONS.NOTIFICATIONS),
        }),
        updateNotificationsSettings: builder.mutation({
            query: ({ id, data }) => {
                return {
                    url:
                        process.env.REACT_APP_NOTIFICATION_API_HOST +
                        ENDPOINTS.notifications.settings +
                        `/${id}`,
                    method: HTTP_METHOD.PATCH,
                    body: data,
                };
            },
            invalidatesTags: (result, error, arg) => [
                { type: NOTIFICATIONS.NOTIFICATIONS, id: arg },
                { type: NOTIFICATIONS.NOTIFICATIONS },
            ],
        }),
        getNotificationsInfo: builder.query({
            query: () => {
                return {
                    url: process.env.REACT_APP_NOTIFICATION_API_HOST + ENDPOINTS.notifications.info,
                    method: HTTP_METHOD.GET,
                };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, NOTIFICATIONS.NOTIFICATIONS_INFO),
        }),
        getInfiniteNotificationsList: builder.query({
            query: (params) => {
                return {
                    url: process.env.REACT_APP_NOTIFICATION_API_HOST + ENDPOINTS.notifications.root,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },

            transformResponse: (
                data: Notification[],
                meta,
            ): { data: Notification[]; totalCount: number } => {
                const xTotalCount = meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },

            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                currentCache.totalCount = newItems.totalCount;
                currentCache.data.push(...newItems.data);
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;
            },

            providesTags: (result) => {
                return result
                    ? [
                          ...result.data.map(({ id }) => ({
                              type: NOTIFICATIONS.NOTIFICATIONS as const,
                              id,
                          })),
                          NOTIFICATIONS.NOTIFICATIONS,
                      ]
                    : [NOTIFICATIONS.NOTIFICATIONS];
            },
        }),
        getNotificationsList: builder.query({
            query: (params) => {
                return {
                    url: process.env.REACT_APP_NOTIFICATION_API_HOST + ENDPOINTS.notifications.root,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (
                data: Notification[],
                meta,
            ): { data: Notification[]; totalCount: number } => {
                const xTotalCount = meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, NOTIFICATIONS.NOTIFICATIONS),
        }),
        readNotifications: builder.mutation({
            query: (ids) => {
                return {
                    url: process.env.REACT_APP_NOTIFICATION_API_HOST + ENDPOINTS.notifications.read,
                    method: HTTP_METHOD.PUT,
                    body: ids,
                };
            },
            invalidatesTags: () => [{ type: NOTIFICATIONS.NOTIFICATIONS_INFO }],
        }),
        deleteNotification: builder.mutation({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_NOTIFICATION_API_HOST +
                        ENDPOINTS.notifications.root +
                        `/${id}`,
                    method: HTTP_METHOD.DELETE,
                };
            },
            invalidatesTags: (result, error, id) => [
                { type: NOTIFICATIONS.NOTIFICATIONS, id },
                { type: NOTIFICATIONS.NOTIFICATIONS, id: 'PARTIAL-LIST' },
            ],
        }),
        snoozeNotification: builder.mutation({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_NOTIFICATION_API_HOST +
                        ENDPOINTS.notifications.snooze +
                        `/${id}`,
                    method: HTTP_METHOD.POST,
                };
            },
            invalidatesTags: () => [{ type: NOTIFICATIONS.NOTIFICATIONS }],
        }),
    }),
});

export const {
    useLazyGetNotificationsSettingsQuery,
    useUpdateNotificationsSettingsMutation,
    useLazyGetInfiniteNotificationsListQuery,
    useGetNotificationsInfoQuery,
    useGetNotificationsListQuery,
    useLazyGetNotificationsListQuery,
    useReadNotificationsMutation,
    useDeleteNotificationMutation,
    useSnoozeNotificationMutation,
} = notificationAPI;

import React from 'react';

import DashboardWrapper from '../../../reusable/DashboardWrapper';
import FarmMainInfo, { MainInfo } from './FarmMainInfo';
import FarmOtherInfo, { OtherInfo } from './FarmOtherInfo';

interface OwnProps {
    mainInfo: MainInfo;
    otherInfo: OtherInfo;
}
export default function FarmDetailsDashboard({ mainInfo, otherInfo }: OwnProps) {
    return (
        <DashboardWrapper>
            <FarmMainInfo {...mainInfo} />
            <FarmOtherInfo {...otherInfo} />
        </DashboardWrapper>
    );
}

import { Box } from '@mui/material';

import DeleteButton from '../../../reusable/Buttons/DeleteButton';
import EditButton from '../../../reusable/Buttons/EditButton';

interface OwnProps {
    id: string;
    onEdit: (id: string) => void;
    onDelete: (id: string) => void;
}

export default function ActionsColumn({ id, onEdit, onDelete }: OwnProps) {
    return (
        <Box display={'flex'} columnGap={1}>
            <EditButton onClick={() => onEdit(id)} />
            <DeleteButton onClick={() => onDelete(id)} />
        </Box>
    );
}

import React, { ReactNode } from 'react';
import Tree, { RawNodeDatum, RenderCustomNodeElementFn } from 'react-d3-tree';

import { makeStyles } from 'tss-react/mui';

import { useCenteredTree } from '../../features/ViewLandownersDetails/LandPartnerRelationship/useCenteredTree';

import { CustomNodeElementProps } from 'react-d3-tree/lib/types/types/common';

interface TreeProps<T> {
    data: T extends RawNodeDatum ? RawNodeDatum : T;
    renderNode: (rd3tProps: CustomNodeElementProps) => ReactNode;
    separation?: { siblings: number; nonSiblings: number };
    zoom?: number;
    depthFactor?: number;
    orientation?: 'horizontal' | 'vertical';
    pathFunc?: 'diagonal' | 'step' | 'straight';
}

export default function ReusableTree<T>({
    data,
    renderNode,
    separation = { siblings: 2, nonSiblings: 2 },
    zoom = 0.9,
    depthFactor = 250,
    orientation = 'vertical',
    pathFunc = 'step',
}: TreeProps<T>) {
    const { classes } = useStyles();
    const [translate, containerRef] = useCenteredTree();

    return (
        <div className={classes.container} ref={containerRef}>
            <Tree
                separation={separation}
                data={data as RawNodeDatum | RawNodeDatum[]}
                zoom={zoom}
                translate={translate}
                depthFactor={depthFactor}
                orientation={orientation}
                pathFunc={pathFunc}
                renderCustomNodeElement={renderNode as unknown as RenderCustomNodeElementFn}
            />
        </div>
    );
}

const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        minHeight: '80vh',
        backgroundColor: theme.palette.neutralWhite,
    },
}));

import alertOverrides from './Alert';
import buttonOverrides from './Button';
import chipOverrides from './Chip';
import palette from './palette';
import { inputBaseOverrides, outlinedInputOverrides } from './TextField';
import typography from './typography';

export default {
    MuiFormControl: {
        styleOverrides: {
            root: {
                '& .MuiOutlinedInput-root': {
                    fontSize: 16,
                    backgroundColor: palette.neutralWhite,
                },
                '& input': {
                    '&:-webkit-autofill': {
                        WebkitBoxShadow: '0 0 0 1000px white inset',
                    },
                },
            },
        },
    },
    MuiInputLabel: {
        styleOverrides: {
            outlined: {
                transform: 'translate(14px, 10px) scale(1)',
            },
            shrink: {
                transform: 'translate(14px, -8px) scale(0.75) !important',
            },
        },
    },
    MuiInputAdornment: {
        styleOverrides: {
            root: {
                '& svg': {
                    color: palette.primary.main,
                },
            },
        },
    },
    MuiButton: buttonOverrides.MuiButton,
    MuiChip: chipOverrides.MuiChip,
    MuiAlert: alertOverrides.MuiAlert,
    MuiInputBase: inputBaseOverrides.MuiInputBase,
    MuiOutlinedInput: outlinedInputOverrides.MuiOutlinedInput,
    MuiAlertTitle: {
        styleOverrides: {
            root: {
                fontSize: '16px',
                fontWeight: 600,
                color: palette.brandDarkTurq,
                margin: 0,
            },
        },
    },
    MuiFormHelperText: {
        styleOverrides: {
            root: {
                margin: 0,
            },
        },
    },
    MuiCalendarPicker: {
        styleOverrides: {
            root: {
                backgroundColor: palette.neutral.neutral1,
                borderRadius: 8,
                '& .Mui-selected': {
                    backgroundColor: `${palette.primary.main} !important`,
                    borderRadius: 4,
                },
                '& .PrivatePickersMonth-root': {
                    '&:hover': {
                        borderRadius: 4,
                    },
                },
                '& .PrivatePickersYear-yearButton': {
                    '&:hover': {
                        borderRadius: 4,
                    },
                },
                '& .MuiPickersDay-dayWithMargin': {
                    '&:hover': {
                        borderRadius: 4,
                    },
                    '&.MuiPickersDay-today': {
                        borderRadius: 4,
                    },
                },
                '& .MuiTypography-root': {
                    fontWeight: 700,
                },
                '& .PrivatePickersSlideTransition-root': {
                    minHeight: 240,
                },
            },
        },
    },
    MuiAutocomplete: {
        styleOverrides: {
            root: {
                '& .MuiAutocomplete-clearIndicator': {
                    color: 'black',
                    padding: 0,
                    '& svg': {
                        height: 12,
                        width: 12,
                    },
                },
            },
        },
    },
    MuiPaper: {
        styleOverrides: {
            root: {
                boxShadow: 'none',
            },
        },
    },
    MuiTableRow: {
        styleOverrides: {
            root: {
                height: 40,
            },
            head: {
                height: 40,
            },
        },
    },
    MuiTableCell: {
        styleOverrides: {
            root: {
                padding: '0 8px',
                borderBottom: 'none',
            },
        },
    },
    MuiTableContainer: {
        styleOverrides: {
            root: {
                '&::-webkit-scrollbar': {
                    width: 10,
                    backgroundColor: 'transparent',
                    padding: 10,
                    scrollbarHeight: 10,
                },
                '&::-webkit-scrollbar-thumb': {
                    width: 5,
                    height: 5,
                    borderRadius: '5px',
                    backgroundColor: 'silver',
                },
            },
        },
    },
    MuiSwitch: {
        styleOverrides: {
            thumb: {
                backgroundColor: 'white',
                boxSizing: 'border-box',
                width: 19,
                height: 19,
                marginTop: '1px',
            },
            track: {
                margin: -5,
                borderRadius: 50 / 2,
                height: 26,
                width: 52,
                backgroundColor: palette.backgroundB.backgroundB4,
                opacity: '1 !important',
                checked: {
                    backgroundColor: palette.primary.main,
                },
            },
        },
    },
    MuiPagination: {
        styleOverrides: {
            root: {
                '& .MuiPaginationItem-root': {
                    color: palette.grey,
                    fontWeight: typography.regular,
                    ...typography.font16,
                },
                '&  .Mui-selected': {
                    color: 'white',
                    backgroundColor: `${palette.primary.main} !important`,
                },
                '& .MuiPaginationItem-previousNext': {
                    '&:first-of-type': {
                        color: palette.grey[350],
                    },
                    '&:last-of-type': {
                        color: palette.grey[750],
                    },
                },
            },
        },
    },
    MuiFormControlLabel: {
        styleOverrides: {
            root: {
                alignItems: 'flex-start',
                margin: 0,
                width: '100%',
            },
            label: {
                color: palette.neutral.neutral7,
                fontSize: '12px',
            },
            asterisk: {
                color: palette.statusRed,
            },
        },
    },
    MuiCheckbox: {
        styleOverrides: {
            root: {
                '& svg': {
                    width: '16px',
                    height: '16px',
                    color: palette.backgroundB.backgroundB4,
                },

                '&.Mui-checked svg': {
                    color: `${palette.primary.main} !important`,
                },
            },
            indeterminate: {
                '& svg': {
                    color: palette.primary.main,
                },
            },
        },
    },
    MuiDivider: {
        styleOverrides: {
            root: {
                borderColor: palette.backgroundB.backgroundB4,
            },
        },
    },
};

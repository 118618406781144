import React from 'react';

import { Box } from '@mui/material';

import AnimatedSpinner from './Spinners/AnimatedSpinner';

interface OwnProps {
    size?: 'small' | 'medium' | 'large';
}

export default function Loader({ size = 'large' }: OwnProps) {
    let dimension;
    if (size === 'small') {
        dimension = 80;
    } else if (size === 'medium') {
        dimension = 100;
    } else {
        dimension = 150;
    }

    return (
        <Box display={'flex'} height={'100%'} alignItems={'center'}>
            <AnimatedSpinner size={dimension} />
        </Box>
    );
}

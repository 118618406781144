import React, { ReactNode, useEffect, useState } from 'react';

import {
    CircularProgress,
    ClickAwayListener,
    InputAdornment,
    List,
    MenuItem,
    Paper,
    Popper,
    TextField,
} from '@mui/material';

import { debounce } from 'lodash';
import Radar from 'radar-sdk-js';

import { RadarAddress } from 'radar-sdk-js/dist/types';

import CloseButton from '../Buttons/CloseButton';

export interface AddressFinderInput {
    onChange: (value: string) => void;
    onSelectAddress: (value: RadarAddress) => void;
    //eslint-disable-next-line
    onBlur?: (e: any) => void;
    value?: string;
    placeholder?: string;
    isDisabled?: boolean;
    isValidationError?: boolean;
    errorText?: string;
    name?: string;
    size?: 'small' | 'medium';
    postfix?: ReactNode | string;
    prefix?: ReactNode | string;
    isHidden?: boolean;
    country?: string;
}

export default function AddressFinderField({
    onChange,
    onSelectAddress,
    onBlur,
    value,
    placeholder,
    isDisabled,
    errorText,
    size,
    postfix,
    isHidden,
    name,
    prefix,
    country = 'US',
}: AddressFinderInput) {
    const [query, setQuery] = useState<string | undefined>(value);
    const [suggestions, setSuggestions] = useState<RadarAddress[]>([]);
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const fetchSuggestions = debounce(async (input: string) => {
        if (input.length > 2) {
            setLoading(true);
            try {
                const result = await Radar.autocomplete({ query: input, countryCode: country });
                setSuggestions(result.addresses || []);
            } catch (error) {
                console.error('Error fetching address suggestions:', error);
                setSuggestions([]);
            } finally {
                setLoading(false);
            }
        } else {
            setSuggestions([]);
        }
    }, 300);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        setQuery(inputValue);
        setAnchorEl(e.currentTarget);
        fetchSuggestions(inputValue);
        onChange(inputValue);
    };

    const handleSelectSuggestion = (address: RadarAddress) => {
        setQuery(address.addressLabel);
        setSuggestions([]);
        setAnchorEl(null);
        onSelectAddress(address);
    };

    const handleClosePopper = () => {
        setSuggestions([]);
        setAnchorEl(null);
    };

    useEffect(() => {
        setQuery(value);
    }, [value]);
    if (isHidden) return null;

    return (
        <ClickAwayListener onClickAway={handleClosePopper}>
            <div>
                <TextField
                    variant="outlined"
                    id={name}
                    onBlur={onBlur}
                    fullWidth
                    disabled={isDisabled}
                    value={query}
                    error={!!errorText}
                    helperText={errorText}
                    placeholder={placeholder}
                    size={size}
                    onChange={handleInputChange}
                    InputProps={{
                        endAdornment: (
                            <>
                                {loading && <CircularProgress size={20} />}
                                {query && (
                                    <CloseButton
                                        size={'small'}
                                        onClick={() => {
                                            setQuery('');
                                            onChange('');
                                        }}
                                    />
                                )}
                                {postfix && (
                                    <InputAdornment position="end">{postfix}</InputAdornment>
                                )}
                            </>
                        ),
                        startAdornment: prefix && (
                            <InputAdornment position="start">{prefix}</InputAdornment>
                        ),
                    }}
                />
                <Popper
                    open={Boolean(anchorEl) && suggestions.length > 0}
                    anchorEl={anchorEl}
                    placement="bottom-start"
                    style={{ zIndex: 1300 }}>
                    <Paper
                        style={{ maxHeight: 250, overflow: 'auto', width: anchorEl?.clientWidth }}>
                        <List>
                            {suggestions.map((suggestion, index) => (
                                <MenuItem
                                    key={index}
                                    onClick={() => handleSelectSuggestion(suggestion)}>
                                    {suggestion.formattedAddress}
                                </MenuItem>
                            ))}
                        </List>
                    </Paper>
                </Popper>
            </div>
        </ClickAwayListener>
    );
}

function trimQuotes(str: string): string {
    return str?.replace(/['"]+/g, '');
}

export function extractExtensionContentDisposition(contentDisposition: string): string {
    const fileName = contentDisposition?.split('filename=')[1];
    return trimQuotes(fileName?.split('.')[1]);
}

export function extractFileNameContentDisposition(contentDisposition: string): string {
    const fileName = contentDisposition.split('filename=')[1];
    return trimQuotes(fileName.split('.')[0]);
}

export function extractExtensionFromFileName(fileName: string): string {
    return fileName?.split('.')[1];
}

export const getFilenameFromContentDisposition = (contentDisposition: string): string => {
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i;
    const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

    let fileName = '';
    const utf8Matches = utf8FilenameRegex.exec(contentDisposition);
    if (utf8Matches !== null && utf8Matches[1]) {
        fileName = decodeURIComponent(utf8Matches[1]);
    } else {
        const filenameStart = contentDisposition.toLowerCase().indexOf('filename=');
        if (filenameStart >= 0) {
            const partialDisposition = contentDisposition.slice(filenameStart);
            const matches = asciiFilenameRegex.exec(partialDisposition);
            if (matches !== null && matches[2]) {
                fileName = matches[2];
            }
        }
    }
    return fileName;
};

export function createDownloadLinkFromBlob(data: Blob, fileName: string) {
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
}

export function downloadFileFromURL(url: string, fileName: string) {
    fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
            createDownloadLinkFromBlob(blob, fileName);
        });
}

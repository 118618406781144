import React, { useMemo, useState } from 'react';

import { FormControlLabel, IconButton, TextField, TextFieldVariants } from '@mui/material';
import { BaseTextFieldProps } from '@mui/material/TextField/TextField';

import clsx from 'clsx';
import { Property } from 'csstype';
import { getIn } from 'formik';
import { makeStyles } from 'tss-react/mui';

import { FormikValues } from 'formik/dist/types';

import { ReactComponent as CloseIcon } from '../../assets/icons/close_icon.svg';
import { ReactComponent as VisibilityIcon } from '../../assets/icons/visibility_icon.svg';
import { ReactComponent as VisibilityOffIcon } from '../../assets/icons/visibility_off_icon.svg';
import {
    getFormikValue,
    isDisplayShrink,
    onChangeWithInitReturn,
} from '../../utils/formikFunctions';

const useStyles = makeStyles()((theme) => ({
    root: {
        '&:hover': {
            '& #close-icon': {
                visibility: 'visible',
            },
        },
    },
    iconButton: {
        padding: 0,
    },
    closeIcon: {
        color: theme.palette.neutral.neutral10,
        height: 12,
        visibility: 'hidden',
        width: 12,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    errorContainer: {
        backgroundColor: theme.palette.statusBackgroundRed,
        color: theme.palette.statusRed,
        padding: '4px',
        borderRadius: '4px',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '1.33',
        // not sure is it good idea
        position: 'absolute',
        width: '100%',
    },
}));

export enum EndIconType {
    close = 'close',
    password = 'password',
}
interface FormTextFieldProps extends BaseTextFieldProps {
    id?: string;
    name: string;
    defaultValue?: string;
    className?: string;
    label: string;
    formik?: FormikValues;
    endIconType?: EndIconType | null;
    InputLabelProps?: React.ComponentProps<typeof TextField>['InputLabelProps'];
    variant?: TextFieldVariants | undefined;
    InputProps?: React.ComponentProps<typeof TextField>['InputProps'];
    textAlign?: Property.TextAlign | undefined;
    onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export default function FormTextField({
    id,
    name,
    label,
    formik,
    multiline = false,
    className = '',
    endIconType = null,
    error = false,
    variant = 'outlined',
    textAlign = 'left',
    ...props
}: FormTextFieldProps) {
    const { classes } = useStyles();
    const [visible, setVisible] = useState(false);
    const value = useMemo(
        () => getFormikValue(getIn(formik?.values, name)),
        [formik?.values, name],
    );

    return (
        <FormControlLabel
            labelPlacement={'top'}
            label={label}
            control={
                <TextField
                    className={clsx(className, classes.root)}
                    fullWidth
                    multiline={multiline}
                    id={id || name}
                    name={name}
                    margin="dense"
                    variant={variant}
                    value={value}
                    onChange={onChangeWithInitReturn(name, formik)}
                    onBlur={formik?.handleBlur}
                    InputLabelProps={
                        formik
                            ? {
                                  shrink: isDisplayShrink(getIn(formik?.values, name)),
                              }
                            : {}
                    }
                    error={error || (getIn(formik?.touched, name) && !!getIn(formik?.errors, name))}
                    helperText={
                        getIn(formik?.touched, name) &&
                        getIn(formik?.errors, name) && (
                            <div className={classes.errorContainer}>
                                {getIn(formik?.touched, name) && getIn(formik?.errors, name)}
                            </div>
                        )
                    }
                    InputProps={
                        endIconType
                            ? {
                                  endAdornment:
                                      endIconType === EndIconType.close ? (
                                          <>
                                              {String(value).length > 0 && (
                                                  <CloseIcon
                                                      id="close-icon"
                                                      className={classes.closeIcon}
                                                      onClick={() => {
                                                          formik?.setFieldValue(name, '');
                                                      }}
                                                  />
                                              )}
                                          </>
                                      ) : (
                                          <IconButton
                                              onClick={() => setVisible((prev) => !prev)}
                                              className={classes.iconButton}>
                                              {!visible ? (
                                                  <VisibilityOffIcon />
                                              ) : (
                                                  <VisibilityIcon />
                                              )}
                                          </IconButton>
                                      ),
                              }
                            : {
                                  inputProps: {
                                      style: { textAlign },
                                  },
                              }
                    }
                    {...props}
                    type={
                        // eslint-disable-next-line no-nested-ternary
                        endIconType === EndIconType.password
                            ? visible
                                ? 'text'
                                : 'password'
                            : props.type
                    }
                />
            }
        />
    );
}

import { Stack } from '@mui/material';

import AppTypography from '../../../design/AppTypography';

interface OwnProps {
    value?: string;
    title: string;
    isHidden?: boolean;
}
export default function PreviewValueWithTitle({ value, title, isHidden }: OwnProps) {
    if (isHidden) return null;

    return (
        <Stack rowGap={1}>
            <AppTypography
                text={title}
                variant={'font14'}
                fontWeight={'medium'}
                color={'text.neutral9'}
            />
            <AppTypography
                text={value}
                variant={'font12'}
                fontWeight={'medium'}
                color={'text.neutral9'}
            />
        </Stack>
    );
}

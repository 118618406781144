import { useContext } from 'react';

import { Box } from '@mui/material';

import TwoColumnLayout from '../../../layouts/TwoColumnLayout';
import theme from '../../../styles/theme';
import PreviewColumnData from './PreviewColumnData';
import PreviewValueWithTitle from './PreviewValueWithTitle';
import { SelectedRowContext } from './SelectedRowContext';

export default function EmergencyContactPreview() {
    const { selectedRow } = useContext(SelectedRowContext);
    if (selectedRow && !selectedRow.id) return null;
    if (!selectedRow) return null;
    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            rowGap={1}
            p={2}
            ml={2}
            borderRadius={theme.spacing(1)}
            bgcolor={theme.palette.lightTurq.lightTurqTint2}>
            <Box pl={1}>
                <PreviewValueWithTitle value={selectedRow.contactName} title={'Name'} />
            </Box>
            <TwoColumnLayout
                type={'half'}
                leftComponent={
                    <PreviewColumnData
                        isPrimary={true}
                        relationshipStartDate={selectedRow.relationshipStartDate}
                        phone={selectedRow.phone.find((p) => p.type === 'OFFICE_PHONE')?.number}
                        primaryEmail={selectedRow.primaryEmail}
                        mailAddress={selectedRow.mailAddress}
                    />
                }
                rightComponent={
                    <PreviewColumnData
                        isPrimary={false}
                        preferredContactMethod={selectedRow.preferredContactMethod}
                        phone={selectedRow.phone.find((p) => p.type === 'CELL_PHONE')?.number}
                        secondaryEmail={selectedRow.secondaryEmail}
                        billingAddress={selectedRow.billingAddress}
                    />
                }
            />
        </Box>
    );
}

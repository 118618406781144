import React from 'react';

import { Box } from '@mui/material';

import AppTypography from '../design/AppTypography';
import UserAvatar, { AvatarSize } from '../design/Avatar/UserAvatar';
import { TypographyVariant, TypographyWeight } from '../styles/typography';

interface OwnProps {
    size: AvatarSize;
    name: string;
    avatar?: string;
    color?: string;
    gap?: number;
    fontWeight?: TypographyWeight;
    typographyType: TypographyVariant;
}
export default function UserAvatarWithName({
    size,
    name,
    avatar,
    typographyType,
    color,
    gap = 1,
    fontWeight,
}: OwnProps) {
    return (
        <Box display={'flex'} columnGap={gap} alignItems={'center'}>
            <UserAvatar name={name} size={size} avatar={avatar} />
            <AppTypography
                flexWrap={true}
                fontWeight={fontWeight}
                text={name}
                variant={typographyType}
                color={color}
            />
        </Box>
    );
}

import { ReactNode } from 'react';

import { Button, ButtonProps } from '@mui/material';

interface OwnProps {
    onClick: () => void;
    isDisabled?: boolean;
    isHidden?: boolean;
    title?: string;
    variant?: ButtonProps['variant'];
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    size?: ButtonProps['size'];
    sx?: ButtonProps['sx'];
}

export default function AppButton({
    onClick,
    isDisabled,
    isHidden,
    title,
    variant,
    startIcon,
    endIcon,
    size,
    sx,
}: OwnProps) {
    if (isHidden) return null;

    return (
        <Button
            sx={sx}
            size={size}
            color={'primary'}
            variant={variant}
            onClick={onClick}
            startIcon={startIcon}
            endIcon={endIcon}
            disabled={isDisabled}>
            {title}
        </Button>
    );
}

import React, { useContext, useState } from 'react';

import { usePagination } from '../../../design/GenericTable/useBackendPagination';
import { useSorting } from '../../../design/GenericTable/useSorting';

import AppTypography from '../../../design/AppTypography';
import { TableColumn } from '../../../design/GenericTable/GenericTable';
import { PhoneType } from '../../../reusable/FormUI/PhoneNumberField';
import ActionsColumn from './ActionsColumn';
import { SelectedRowContext } from './SelectedRowContext';

export interface EmergencyContact {
    id: string;
    contactName: string;
    jobTitle: string;
    relationship: string;
    relationshipStartDate: string;
    preferredContactMethod: string;
    primaryEmail: string;
    secondaryEmail: string;
    phone: PhoneType[];
    mailAddress: string;
    billingAddress: string;
}

export default function useEmergencyContactsConfig() {
    const [isShowing, setIsShowing] = useState(false);
    const [deleteData, setDeleteData] = useState<EmergencyContact | null>(null);

    const { sortKey, sortOrder, handleSort } = useSorting('name', 'asc');

    const {
        // currentPage,
        // itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(1, 100);

    const { setSelectedRow } = useContext(SelectedRowContext);

    function handleDeleteClick(data: EmergencyContact) {
        setIsShowing(true);
        setDeleteData(data);
    }

    const handleRowClick = (rowData: EmergencyContact) => {
        setSelectedRow(rowData);
    };

    const columns: TableColumn<EmergencyContact>[] = [
        {
            header: {
                label: 'Name',
                sortable: true,
                sorting: {
                    id: 'name',
                    direction: sortKey === 'name' ? sortOrder : 'desc',
                    isSorted: sortKey === 'name',
                },
                onClick: handleSort,
            },
            cellRender: (contact) => (
                <AppTypography
                    text={contact.contactName}
                    color={'text.link'}
                    fontWeight={'medium'}
                />
            ),

            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Job Title',
                sortable: false,
                sorting: {
                    id: 'jobTitle',
                    direction: sortKey === 'jobTitle' ? sortOrder : 'desc',
                    isSorted: sortKey === 'jobTitle',
                },
                onClick: handleSort,
            },
            cellRender: (contact) => <>{contact.jobTitle}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Relationship',
                sortable: false,
                sorting: {
                    id: 'relationship',
                    direction: sortKey === 'relationship' ? sortOrder : 'desc',
                    isSorted: sortKey === 'relationship',
                },
                onClick: handleSort,
            },
            cellRender: (contact) => <>{contact.relationship}</>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Actions',
                sortable: false,
            },
            cellRender: (contact) => (
                <ActionsColumn
                    id={contact.id}
                    onDelete={() => handleDeleteClick(contact)}
                    /* eslint-disable-next-line no-console */
                    onEdit={() => console.log(contact.id)}
                />
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
    ];

    const tableConfig = {
        columns,
        pageable: false,
        handlePageChange,
        onClick: handleRowClick,
        handleItemsPerPageChange,
        initialPageSize: 5,
        initialPage: 1,
    };
    const totalCount = mockData?.length || 0; //TODO change on total count after removing mocks

    return {
        data: mockData,
        totalCount,
        tableConfig,
        deleteData,
        isShowing,
        setIsShowing,
    };
}
const mockData: EmergencyContact[] = [
    {
        id: 'ec1',
        contactName: 'John Doe',
        jobTitle: 'Operations Manager',
        relationship: 'Colleague',
        relationshipStartDate: '2015-06-12',
        preferredContactMethod: 'Email',
        primaryEmail: 'johndoe@example.com',
        secondaryEmail: 'j.doe@OFFICE_PHONEplace.com',
        phone: [
            { type: 'FAX', number: '+1-555-123-4567' },
            { type: 'CELL_PHONE', number: '+1-555-987-6543' },
        ],
        mailAddress: '123 Maple Street, Springfield, USA',
        billingAddress: '707 Poplar Street, Midtown, USA',
    },
    {
        id: 'ec2',
        contactName: 'Jane Smith',
        jobTitle: 'HR Director',
        relationship: 'Supervisor',
        relationshipStartDate: '2018-01-22',
        preferredContactMethod: 'Phone',
        primaryEmail: 'jane.smith@corporate.com',
        secondaryEmail: 'jane.s@personalmail.com',
        phone: [
            { type: 'CELL_PHONE', number: '+1-555-234-5678' },
            { type: 'OFFICE_PHONE', number: '+1-555-876-5432' },
        ],
        mailAddress: '456 Oak Avenue, Metropolis, USA',
        billingAddress: '707 Poplar Street, Midtown, USA',
    },
    {
        id: 'ec3',
        contactName: 'Robert Johnson',
        jobTitle: 'Project Coordinator',
        relationship: 'Friend',
        relationshipStartDate: '2010-11-09',
        preferredContactMethod: 'Email',
        primaryEmail: 'robert.johnson@mail.com',
        secondaryEmail: 'r.johnson@outlook.com',
        phone: [{ type: 'CELL_PHONE', number: '+1-555-345-6789' }],
        mailAddress: '789 Pine Road, Smallville, USA',
        billingAddress: '707 Poplar Street, Midtown, USA',
    },
    {
        id: 'ec4',
        contactName: 'Emily Davis',
        jobTitle: 'Team Lead',
        relationship: 'Colleague',
        relationshipStartDate: '2017-05-15',
        preferredContactMethod: 'Phone',
        primaryEmail: 'emily.davis@company.com',
        secondaryEmail: 'emily.d@gmail.com',
        phone: [
            { type: 'OFFICE_PHONE', number: '+1-555-456-7890' },
            { type: 'CELL_PHONE', number: '+1-555-654-3210' },
        ],
        mailAddress: '101 Birch Lane, River City, USA',
        billingAddress: '707 Poplar Street, Midtown, USA',
    },
    {
        id: 'ec5',
        contactName: 'Michael Brown',
        jobTitle: 'IT Specialist',
        relationship: 'Cousin',
        relationshipStartDate: '2008-03-21',
        preferredContactMethod: 'Email',
        primaryEmail: 'michael.brown@techmail.com',
        secondaryEmail: 'mike.brown@yahoo.com',
        phone: [{ type: 'OFFICE_PHONE', number: '+1-555-567-8901' }],
        mailAddress: '202 Cedar Street, town, USA',
        billingAddress: '707 Poplar Street, Midtown, USA',
    },
    {
        id: 'ec6',
        contactName: 'Lisa Wilson',
        jobTitle: 'Marketing Manager',
        relationship: 'Neighbor',
        relationshipStartDate: '2019-09-14',
        preferredContactMethod: 'Phone',
        primaryEmail: 'lisa.wilson@marketinginc.com',
        secondaryEmail: 'l.wilson@personal.com',
        phone: [
            { type: 'CELL_PHONE', number: '+1-555-678-9012' },
            { type: 'OFFICE_PHONE', number: '+1-555-432-1098' },
        ],
        mailAddress: '303 Elm Drive, Suburbia, USA',
        billingAddress: '707 Poplar Street, Midtown, USA',
    },
    {
        id: 'ec7',
        contactName: 'David Martinez',
        jobTitle: 'Finance Analyst',
        relationship: 'Friend',
        relationshipStartDate: '2012-07-10',
        preferredContactMethod: 'Email',
        primaryEmail: 'david.martinez@financeservices.com',
        secondaryEmail: 'd.martinez@outlook.com',
        phone: [{ type: 'OFFICE_PHONE', number: '+1-555-789-0123' }],
        mailAddress: '404 Spruce Street, Urban City, USA',
        billingAddress: '707 Poplar Street, Midtown, USA',
    },
    {
        id: 'ec8',
        contactName: 'Sarah Lee',
        jobTitle: 'Account Manager',
        relationship: 'Sibling',
        relationshipStartDate: '1998-04-30',
        preferredContactMethod: 'Phone',
        primaryEmail: 'sarah.lee@accounting.com',
        secondaryEmail: 's.lee@familymail.com',
        phone: [{ type: 'OFFICE_PHONE', number: '+1-555-890-1234' }],
        mailAddress: '505 Willow Court, Downtown, USA',
        billingAddress: '707 Poplar Street, Midtown, USA',
    },
    {
        id: 'ec9',
        contactName: 'James White',
        jobTitle: 'Sales Executive',
        relationship: 'Uncle',
        relationshipStartDate: '2005-02-18',
        preferredContactMethod: 'Email',
        primaryEmail: 'james.white@salescompany.com',
        secondaryEmail: 'j.white@freemail.com',
        phone: [
            { type: 'CELL_PHONE', number: '+1-555-901-2345' },
            { type: 'OFFICE_PHONE', number: '+1-555-543-2109' },
        ],
        billingAddress: '707 Poplar Street, Midtown, USA',
        mailAddress: '606 Ash Avenue, Uptown, USA',
    },
    {
        id: 'ec10',
        contactName: 'Karen Thompson',
        jobTitle: 'Executive Assistant',
        relationship: 'Colleague',
        relationshipStartDate: '2020-11-22',
        preferredContactMethod: 'Phone',
        primaryEmail: 'karen.thompson@executivecorp.com',
        secondaryEmail: 'k.thompson@personal.com',
        phone: [
            { type: 'FAX', number: '+1-555-012-3456' },
            { type: 'CELL_PHONE', number: '+1-555-210-9876' },
        ],
        mailAddress: '707 Poplar Street, Midtown, USA',
        billingAddress: '606 Ash Avenue, Uptown, USA',
    },
];

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { TOKEN_KEY } from '../constant/localStorageKeys';
import { HTTP_METHOD } from './auth/http';
import { ENDPOINTS } from './endpoints';

export const fileApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_LEASE_API_HOST,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem(TOKEN_KEY);
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getFile: builder.query({
            query: (id) => ({
                url: ENDPOINTS.leaseDocuments.root + `/${id}`,
                cache: 'no-cache',
                responseHandler: async (response: Response) => {
                    const headers = response.headers.get('content-disposition');
                    const data = await response.blob();
                    return { data, headers };
                },
            }),
        }),
        getBulkDocuments: builder.query({
            query: (leaseIds) => ({
                url:
                    process.env.REACT_APP_LEASE_API_HOST +
                    ENDPOINTS.leases.root +
                    '/download' +
                    `?${leaseIds}`,
                method: HTTP_METHOD.GET,
                cache: 'no-cache',
                responseHandler: async (response: Response) => {
                    const headers = response.headers.get('content-disposition');
                    const data = await response.blob();
                    return { data, headers };
                },
            }),
        }),
        getLandPartnerFile: builder.query({
            query: (documentId) => ({
                url:
                    process.env.REACT_APP_USER_API_HOST +
                    ENDPOINTS.users.documents +
                    `/${documentId}`,
            }),
        }),
        getFileByURL: builder.query({
            query: (url) => ({
                url: url,
                cache: 'no-cache',
                responseHandler: async (response: Response) => {
                    const headers = response.headers.get('content-disposition');
                    const data = await response.blob();
                    return { data, headers };
                },
            }),
        }),
        downloadBulkLandPartner: builder.query({
            query: (documentIds) => {
                const baseUrl =
                    process.env.REACT_APP_USER_API_HOST + ENDPOINTS.landpartners.download;
                const url = documentIds ? `${baseUrl}?${documentIds}` : baseUrl;
                return {
                    url: url,
                    method: HTTP_METHOD.GET,
                    cache: 'no-cache',
                    responseHandler: async (response: Response) => {
                        const headers = response.headers.get('content-disposition');

                        const data = await response.blob();
                        return { data, headers };
                    },
                };
            },
        }),
        downloadBulkContacts: builder.query({
            query: (documentIds) => {
                let url;
                if (!documentIds) {
                    return (url =
                        process.env.REACT_APP_USER_API_HOST + ENDPOINTS.contacts.download);
                }
                url =
                    process.env.REACT_APP_USER_API_HOST +
                    ENDPOINTS.contacts.download +
                    `?${documentIds}`;
                return {
                    url: url,
                    method: HTTP_METHOD.GET,
                    cache: 'no-cache',
                    responseType: 'blob',
                    responseHandler: async (response: Response) => {
                        const headers = response.headers.get('content-disposition');
                        const data = await response.blob();
                        return { data, headers };
                    },
                };
            },
        }),
    }),
});

export const {
    useLazyDownloadBulkContactsQuery,
    useLazyDownloadBulkLandPartnerQuery,
    useLazyGetFileByURLQuery,
    useGetFileQuery,
    useLazyGetFileQuery,
    useLazyGetBulkDocumentsQuery,
    useLazyGetLandPartnerFileQuery,
} = fileApi;

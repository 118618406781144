import React, { useState } from 'react';

import { Stack } from '@mui/material';

import { EmergencyContact } from './useTableEmergencyContactsConfig';

import AppButton from '../../../design/Buttons/AppButton';
import TwoColumnLayout from '../../../layouts/TwoColumnLayout';
import EmergencyContactPreview from './EmergencyContactPreview';
import EmergencyTable from './EmergencyTable';
import { SelectedRowContext } from './SelectedRowContext';

export default function EmergencyContacts() {
    const [selectedRow, setSelectedRow] = useState<EmergencyContact | null>(
        {} as EmergencyContact | null,
    );
    return (
        <SelectedRowContext.Provider value={{ selectedRow, setSelectedRow }}>
            <Stack spacing={4} alignItems={'flex-end'}>
                <AppButton
                    /* eslint-disable-next-line @typescript-eslint/no-empty-function */
                    onClick={() => {}}
                    title="Add Another Emergency Contact"
                    variant={'contained'}
                />
                <TwoColumnLayout
                    type={'twoThirds'}
                    leftComponent={<EmergencyTable />}
                    rightComponent={<EmergencyContactPreview />}
                />
            </Stack>
        </SelectedRowContext.Provider>
    );
}

import React, { useState } from 'react';

import { Card, IconButton } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { ReactComponent as NotesIcon } from '../../../assets/icons/edit_notes_icon.svg';
import AppTypography from '../../../design/AppTypography';
import TreeCardAvatar from '../../../reusable/Tree/TreeCardAvatar';
import TreeNode from '../../../reusable/Tree/TreeNode';
import FarmTeamNotes from './FarmTeamNotes';
import { CombinedFarmNodeDatum } from './FarmTeamTree';

export interface OwnProps {
    nodeDatum: CombinedFarmNodeDatum;
}
export default function FarmTeamCard({ nodeDatum }: OwnProps) {
    const [isNotesShowing, setIsNotesShowing] = useState(false);
    const [currentId, setCurrentId] = useState<string | undefined>();
    const { classes } = useStyles();

    // const { avatarUrl: farmLogo } = useAvatar(
    //     nodeDatum?.farmLogo?.farmLogo,
    //     nodeDatum?.farmLogo?.fileType,
    // );

    const handleNotesClose = () => {
        setCurrentId(undefined);
        setIsNotesShowing(false);
    };
    const handleNotesOpen = () => {
        setCurrentId(nodeDatum.id);
        setIsNotesShowing(true);
    };

    return (
        <>
            <TreeNode isDisabled={nodeDatum.isDisabled} nodeDatum={nodeDatum}>
                <TreeCardAvatar
                    avatar={nodeDatum.farmLogo}
                    name={`${nodeDatum.firstName} ${nodeDatum.lastName}`}
                />
                <Card className={classes.cardContainer}>
                    {
                        <div className={classes.actionButtonContainer}>
                            <IconButton onClick={handleNotesOpen}>
                                <NotesIcon />
                            </IconButton>
                        </div>
                    }
                    <AppTypography
                        variant={'font16'}
                        color={'text.secondary'}
                        fontWeight={'medium'}
                        text={`${nodeDatum.firstName} ${nodeDatum.lastName}`}
                    />
                    <AppTypography
                        variant={'font12'}
                        textTransform={'capitalize'}
                        color={'text.secondary'}
                        text={nodeDatum.role}
                    />
                </Card>
            </TreeNode>
            {isNotesShowing && currentId && (
                <FarmTeamNotes
                    id={currentId}
                    contact={nodeDatum}
                    isShowing={isNotesShowing}
                    onClose={handleNotesClose}
                />
            )}
        </>
    );
}

const useStyles = makeStyles()((theme) => ({
    actionButtonContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        backgroundColor: theme.palette.neutral.neutral2,
        padding: theme.spacing(0, 2, 2),
        borderRadius: '12px',
        borderStyle: 'none !important',
    },
}));

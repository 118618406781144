import React from 'react';

import { Box, Button, Divider } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import StatusTag, { Tag } from '../../design/StatusTag/StatusTag';
import PersonalInfoWithLabel from '../ReadOnlyFields/PersonalInfoWithLabel';
import UserMainInfo, { UserMain } from './UserMainInfo';

interface UserData {
    date: string;
    location?: string;
    email: string;
    phone: string;
    birthday: string;
}

export interface UserInfo {
    id: string;
    mainInfo: UserMain;
    userData: UserData;
    isEditable?: boolean;
    tag?: Tag;
    isDeceased?: boolean;
}
export default function UserInfoPanel({ mainInfo, tag, userData, isEditable }: UserInfo) {
    const { classes } = useStyles();

    return (
        <Box className={classes.containerWrapper}>
            <Box display={'flex'}>
                <UserMainInfo {...mainInfo} />
                <Box display={'flex'} flexDirection={'column'}>
                    <Box display={'flex'} columnGap={4}>
                        <Divider orientation={'vertical'} flexItem variant="middle" />

                        <div className={classes.infoWrapper}>
                            {tag && <StatusTag {...tag} />}
                            {Object.keys(userData).map((item, index) => {
                                return (
                                    !!userData[item as keyof UserData] && (
                                        <PersonalInfoWithLabel
                                            key={index}
                                            text={userData[item as keyof UserData]}
                                            type={item as keyof UserData}
                                        />
                                    )
                                );
                            })}
                        </div>
                    </Box>
                </Box>
            </Box>
            {isEditable && <Button variant={'outlined'}>Edit</Button>}
        </Box>
    );
}

const useStyles = makeStyles()((theme) => ({
    containerWrapper: {
        width: '100%',
        maxHeight: 250,
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2, 3),
        backgroundColor: theme.palette.neutralWhite,
        borderRadius: 16,
    },
    infoWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        columnGap: theme.spacing(4),
        rowGap: theme.spacing(2),
        padding: theme.spacing(2, 4),
        maxHeight: 100,
    },
}));

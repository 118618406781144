import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import Radar from 'radar-sdk-js';
import { TssCacheProvider } from 'tss-react';

import { store } from './api/store';
import FullScreenLoader from './design/Spinners/FullScreenLoader';
import { RouterConfig } from './router/RouterConfig';
import theme from './styles/theme';

import 'react-toastify/dist/ReactToastify.css';
import './styles/styles.css';

Radar.initialize(process.env.REACT_APP_RADAR_API_KEY as string);

export default function App() {
    const muiCache = createCache({
        key: 'mui',
        prepend: true,
    });

    const tssCache = createCache({
        key: 'tss',
    });
    return (
        <Provider store={store}>
            <CacheProvider value={muiCache}>
                <TssCacheProvider value={tssCache}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            <Suspense fallback={<FullScreenLoader />}>
                                <RouterProvider router={RouterConfig} />
                            </Suspense>
                        </ThemeProvider>
                    </LocalizationProvider>
                </TssCacheProvider>
            </CacheProvider>
            <ToastContainer />
        </Provider>
    );
}

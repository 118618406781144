import React, { ReactNode } from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IconButton, Tooltip as MUITooltip } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

export interface GenericTooltip {
    text: ReactNode;
    position?: 'top' | 'bottom' | 'left' | 'right';
}
export default function InfoTooltip({
    text,

    position = 'bottom',
}: GenericTooltip) {
    const { classes } = useStyles();

    return (
        <MUITooltip title={text} arrow placement={position}>
            <IconButton className={classes.buttonTooltip}>
                <InfoOutlinedIcon className={classes.iconTooltip} />
            </IconButton>
        </MUITooltip>
    );
}
const useStyles = makeStyles()((theme) => ({
    buttonTooltip: {
        padding: 0,
    },
    iconTooltip: {
        color: theme.palette.brandLightTurq,
        height: 14,
        width: 14,
    },
}));

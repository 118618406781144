import { TaskView } from '../../features/Tasks/utils';
import { HTTP_METHOD } from '../auth/http';
import { ENDPOINTS } from '../endpoints';
import { getListProvidesTags } from '../generic';
import { OAKENApi } from '../OAKENApi';
import { TASKS } from '../tags';

interface ArchivedTasksTransformed {
    data: ArchivedTaskResponse[];
    totalCount: number;
}

export interface ArchivedTaskResponse {
    id: string;
    name: string;
    description?: string;
    priority: string;
    dueDate?: string;
    assignTo?: UserNameResponse;
    watchers?: UserNameResponse[];
    relatesTo?: {
        id: string;
        type: string;
    };
    updatedAt: number;
    createdAt: number;
    archivedBy: UserNameResponse;
}

interface UserNameResponse {
    id: string;
    firstName: string;
    lastName: string;
}

interface PageableRequestParams {
    page: number;
    size: number;
    sort?: string;
}

export const tasksAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        getTasks: builder.query({
            query: (params) => {
                return {
                    url: process.env.REACT_APP_USER_API_HOST + ENDPOINTS.tasks.root,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (
                data: TaskView[],
                meta,
            ): { data: TaskView[]; totalCount: number } => {
                const xTotalCount = meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) => getListProvidesTags(result?.data, TASKS.TASKS),
        }),
        getOneTask: builder.query({
            query: (id) => {
                return {
                    url: process.env.REACT_APP_USER_API_HOST + ENDPOINTS.tasks.root + `/${id}`,
                    method: HTTP_METHOD.GET,
                };
            },
        }),
        addTask: builder.mutation({
            query: ({ documents, ...values }) => {
                const formData = new FormData();
                if (documents) {
                    documents.forEach((file: File) => {
                        formData.append(`documents`, file, file.name);
                    });
                }
                formData.append(
                    'request',
                    new Blob([JSON.stringify(values)], {
                        type: 'application/json',
                    }),
                );

                return {
                    url: process.env.REACT_APP_USER_API_HOST + ENDPOINTS.tasks.root,
                    method: HTTP_METHOD.POST,
                    body: formData,
                };
            },
            invalidatesTags: [TASKS.TASKS],
        }),
        updateTask: builder.mutation({
            query: ({ documents, updateSeries, ...values }) => {
                const formData = new FormData();
                if (documents) {
                    documents.forEach((file: File) => {
                        formData.append(`documents`, file, file.name);
                    });
                }
                formData.append(
                    'request',
                    new Blob([JSON.stringify(values)], {
                        type: 'application/json',
                    }),
                );

                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.tasks.root +
                        `/${values.id}`,
                    method: HTTP_METHOD.PUT,
                    body: formData,
                    params: { updateSeries },
                };
            },
            invalidatesTags: [TASKS.TASKS],
        }),
        getListOfDocuments: builder.query({
            query: ({ id, ...params }) => {
                return {
                    url: process.env.REACT_APP_USER_API_HOST + ENDPOINTS.tasks.document + `/${id}`,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
        }),
        getListOfHistory: builder.query({
            query: ({ id, ...params }) => {
                return {
                    url: process.env.REACT_APP_USER_API_HOST + ENDPOINTS.tasks.history + `/${id}`,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
        }),
        getTasksSummary: builder.query({
            query: (params) => {
                return {
                    url: process.env.REACT_APP_USER_API_HOST + ENDPOINTS.tasks.summary,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            providesTags: (result) => getListProvidesTags(result?.data, TASKS.TASKS),
        }),
        markTaskAsDone: builder.mutation({
            query: (id) => {
                return {
                    url: process.env.REACT_APP_USER_API_HOST + ENDPOINTS.tasks.done + `/${id}`,
                    method: HTTP_METHOD.POST,
                };
            },
            invalidatesTags: [TASKS.TASKS],
        }),
        assignedTasks: builder.query({
            query: (params) => {
                return {
                    url: process.env.REACT_APP_USER_API_HOST + ENDPOINTS.tasks.assigned,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (
                data: TaskView[],
                meta,
            ): { data: TaskView[]; totalCount: number } => {
                const xTotalCount = meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) => getListProvidesTags(result?.data, TASKS.TASKS),
        }),
        archiveTaskById: builder.mutation<void, { taskId: string; archiveSeries?: boolean }>({
            query: ({ taskId, archiveSeries }) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.tasks.archive +
                        `/${taskId}`,
                    method: HTTP_METHOD.POST,
                    params: { archiveSeries },
                };
            },
            invalidatesTags: [TASKS.TASKS, TASKS.ARCHIVED_TASKS],
        }),
        getArchivedTasks: builder.query<ArchivedTasksTransformed, PageableRequestParams>({
            query: (params) => {
                return {
                    url: process.env.REACT_APP_USER_API_HOST + ENDPOINTS.tasks.archived,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (data: ArchivedTaskResponse[], meta): ArchivedTasksTransformed => {
                const xTotalCount = meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) => getListProvidesTags(result?.data, TASKS.ARCHIVED_TASKS),
        }),
        restoreTaskById: builder.mutation<void, string>({
            query: (id) => {
                return {
                    url: process.env.REACT_APP_USER_API_HOST + ENDPOINTS.tasks.restore + `/${id}`,
                    method: HTTP_METHOD.POST,
                };
            },
            invalidatesTags: [TASKS.TASKS, TASKS.ARCHIVED_TASKS],
        }),
        deleteTaskById: builder.mutation({
            query: (id) => {
                return {
                    url: process.env.REACT_APP_USER_API_HOST + ENDPOINTS.tasks.archived + `/${id}`,
                    method: HTTP_METHOD.DELETE,
                };
            },
            invalidatesTags: [TASKS.ARCHIVED_TASKS],
        }),
    }),
});

export const {
    useAssignedTasksQuery,
    useMarkTaskAsDoneMutation,
    useGetTasksSummaryQuery,
    useLazyGetTasksQuery,
    useLazyGetOneTaskQuery,
    useAddTaskMutation,
    useUpdateTaskMutation,
    useLazyGetListOfDocumentsQuery,
    useLazyGetListOfHistoryQuery,
    useArchiveTaskByIdMutation,
    useGetArchivedTasksQuery,
    useRestoreTaskByIdMutation,
    useDeleteTaskByIdMutation,
} = tasksAPI;

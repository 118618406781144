import React from 'react';

import { FormControl, MenuItem, Select, Typography } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

export interface Option {
    id: string;
    name: string;
}
interface OwnProps {
    options: Option[];
    option: string;
    handleChange: (value: string) => void;
}
export default function GroupByDropdown({ options, option, handleChange }: OwnProps) {
    const { classes } = useStyles();

    return (
        <>
            <Typography variant="font16" fontWeight="medium">
                Group by
            </Typography>
            <FormControl variant="outlined">
                <Select
                    id={'filterList'}
                    sx={{
                        minWidth: 350,
                        height: 32,
                    }}
                    fullWidth
                    value={option}
                    onChange={(e) => {
                        handleChange(e.target.value);
                    }}
                    displayEmpty>
                    <MenuItem disabled value="">
                        <p>Select Type</p>
                    </MenuItem>
                    {options?.map((option: Option) => (
                        <MenuItem className={classes.menuItem} key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
}

const useStyles = makeStyles()(() => ({
    menuItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
}));

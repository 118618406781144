import React, { FocusEvent } from 'react';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export interface DatePickerInput {
    onChange: (date: Date | null | number) => void;
    onBlur: (e: FocusEvent<HTMLInputElement>) => void;
    value?: Date;
    placeholder?: string;
    isDisabled?: boolean;
    errorText?: string;
    size?: 'small' | 'medium';
    name?: string;
    isHidden?: boolean;
    format?: string;
    height?: number;
    isDateWithoutYear?: boolean;
    views?: ('year' | 'month' | 'day')[];
}

export default function DatePickerField({
    onChange,
    onBlur,
    value,
    placeholder,
    isDisabled,
    errorText,
    size,
    isHidden,
    name,
    format = 'yyyy-MM-dd',
    views,
    isDateWithoutYear,
}: DatePickerInput) {
    if (isHidden) return null;

    return (
        <LocalizationProvider
            dateAdapter={AdapterDateFns}
            localeText={{
                fieldMonthPlaceholder: (params) => (params.contentType === 'digit' ? 'MM' : 'MMM'),
            }}
            dateFormats={{
                monthAndYear: isDateWithoutYear ? 'MMM' : format,
            }}>
            <DatePicker
                showDaysOutsideCurrentMonth
                disabled={isDisabled}
                value={value}
                onChange={(e) => onChange(e ? e.getTime() : null)}
                slotProps={{
                    textField: {
                        id: name,
                        onBlur: onBlur,
                        error: !!errorText,
                        helperText: errorText,
                        placeholder: placeholder,
                        size: size,
                    },
                    inputAdornment: { position: 'start', variant: 'standard' },
                    day: {
                        sx: (theme) => ({
                            '&.MuiPickersDay-dayOutsideMonth': {
                                color: theme.palette.grey[400],
                            },
                        }),
                    },
                }}
                views={views}
                format={format}
            />
        </LocalizationProvider>
    );
}

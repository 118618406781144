import React from 'react';

import BasicTabsPanel from '../../design/TabsPanel/BasicTabsPanel';
import PATHS from '../../router/paths';
import EmergencyContacts from './EmergencyContacts/EmergencyContacts';
import FarmDocuments from './FarmDocuments/FarmDocuments';
import FarmTeam from './FarmTeam/FarmTeam';

export default function FarmProfileDetails() {
    const tabsConfig = [
        {
            label: 'Emergency Contacts ',
            content: <EmergencyContacts />,
            value: 0,
            to: '',
        },
        {
            label: 'Farm Planning',
            content: <p>Farm Planning </p>,
            value: `${PATHS.planning}`,
            to: `${PATHS.planning}`,
        },
        {
            label: 'Farm Team',
            content: <FarmTeam />,
            value: `${PATHS.team}`,
            to: `${PATHS.team}`,
        },
        {
            label: 'Farm Documents',
            content: <FarmDocuments />,
            value: `${PATHS.documents}`,
            to: `${PATHS.documents}`,
        },
    ];
    return <BasicTabsPanel tabItems={tabsConfig} />;
}

import React, { ReactNode, useState } from 'react';

import { IconButton, Tooltip, Typography } from '@mui/material';

import copy from 'copy-to-clipboard';
import { makeStyles } from 'tss-react/mui';

import { ReactComponent as CopyIcon } from '../../assets/icons/copy_icon.svg';
import BasedTooltip from '../BasedTooltip';

export interface ReadOnlyFieldProps {
    title: string;
    text?: string;
    value?: string | number | ReactNode;
    hasCopied?: boolean;
    isHidden?: boolean;
}

export default function CopiedReadField({
    title,
    value,
    hasCopied = false,
    text = '',
    isHidden = false,
}: ReadOnlyFieldProps) {
    const { classes } = useStyles();
    const [tooltipText, setTooltipText] = useState('Copy to clipboard');

    if (isHidden) return null;

    const handleCopy = () => {
        if (value) {
            const textToCopy = text || value.toString();
            copy(textToCopy);
            setTooltipText('Copied!');
            setTimeout(() => setTooltipText('Copy to clipboard'), 2000);
        }
    };

    return (
        <div className={classes.container}>
            <div className={classes.titleRow}>
                <BasedTooltip text={title}>
                    <Typography variant="font14" fontWeight="bold" className={classes.title}>
                        {title}
                    </Typography>
                </BasedTooltip>
                {hasCopied && value && (
                    <Tooltip title={tooltipText} arrow>
                        <IconButton onClick={handleCopy} className={classes.iconButton}>
                            <CopyIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
            <div className={classes.valueRow}>
                <BasedTooltip text={typeof value === 'string' ? value : ''}>
                    <Typography variant="font12" className={classes.value}>
                        {value}
                    </Typography>
                </BasedTooltip>
            </div>
        </div>
    );
}

const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '20%',
        paddingBottom: theme.spacing(3),
    },
    titleRow: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: theme.spacing(1),
        gap: theme.spacing(1),
        width: '100%',
        overflow: 'hidden',
    },
    title: {
        marginBottom: theme.spacing(1),
        textAlign: 'left',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    valueRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    value: {
        textAlign: 'left',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    iconButton: {
        padding: theme.spacing(0),
        marginLeft: theme.spacing(1.5),
    },
}));

import { ReactNode, useState } from 'react';

import { Box, Collapse, IconButton, Typography } from '@mui/material';

import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';

import { ReactComponent as ExpandIcon } from '../assets/icons/expand_icon.svg';
import theme from '../styles/theme';
interface OwnProps {
    children: ReactNode;
    isCollapsed?: boolean;
    title?: string;
    onClick?: () => void;
    isHidden?: boolean;
    type?: 'regular' | 'contrasted';
}
export default function CollapsedComponent({
    children,
    isCollapsed = true,
    title,
    onClick,
    isHidden,
    type = 'regular',
}: OwnProps) {
    const { classes } = useStyles();
    const [collapsed, setCollapsed] = useState(isCollapsed);
    const handleCollapse = () => {
        setCollapsed(!collapsed);
        onClick && onClick();
    };
    if (isHidden) return null;
    return (
        <>
            <Box
                display={'flex'}
                justifyContent={'space-between'}
                sx={{
                    backgroundColor:
                        (type === 'regular' && theme.palette.backgroundB.backgroundB2) ||
                        (type === 'contrasted' && theme.palette.brandYellowGreen) ||
                        theme.palette.backgroundB.backgroundB2,
                }}
                className={classes.expandHeader}>
                <Typography variant={'font14'} fontWeight={'regular'} color={'text.link'}>
                    {title}
                </Typography>
                <div>
                    <Typography variant={'font12'} color={'text.link'} fontWeight={'medium'}>
                        {collapsed ? 'Expand' : 'Collapse'}
                    </Typography>
                    <IconButton onClick={handleCollapse}>
                        <ExpandIcon className={clsx(!collapsed && classes.expanded)} />
                    </IconButton>
                </div>
            </Box>
            <Collapse in={!collapsed} timeout="auto" unmountOnExit>
                {children}
            </Collapse>
        </>
    );
}
const useStyles = makeStyles()(() => ({
    expandHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: theme.spacing(0, 1),
    },
    expanded: {
        width: 24,
        height: 24,
        transform: 'rotate(180deg)',
    },
}));

import React, { ReactNode } from 'react';

import { Grid, Typography } from '@mui/material';

type Scheme = 'simple' | 'third' | 'half' | 'twoThirds' | 'oneThirds' | 'oneSixth' | 'fiveSixths';

interface OwnProps {
    leftComponent?: ReactNode;
    rightComponent?: ReactNode;
    type: Scheme;
    header?: string;
}

const schemeMapping: Record<Scheme, number> = {
    simple: 1,
    third: 4,
    half: 6,
    twoThirds: 8,
    oneThirds: 9,
    fiveSixths: 9.7,
    oneSixth: 10,
};
export default function TwoColumnLayout({ leftComponent, rightComponent, type, header }: OwnProps) {
    const columnWidth = schemeMapping[type];
    return (
        <Grid container p={1}>
            {header && (
                <Typography variant="font32" color={'text.light'} fontWeight={'regular'}>
                    {header}
                </Typography>
            )}
            <Grid container item>
                <Grid item xs={columnWidth}>
                    {leftComponent}
                </Grid>
                <Grid item xs={12 - columnWidth} pl={1}>
                    {rightComponent}
                </Grid>
            </Grid>
        </Grid>
    );
}

import React, { useState } from 'react';

import { Grid, MenuItem, PaginationItem, TextField, Typography } from '@mui/material';
import Pagination from '@mui/material/Pagination';

import { makeStyles } from 'tss-react/mui';

/**
 * TODO - refactor code, change interface
 */

export interface OwnProps {
    handleMovePage: (a: { page: number; pageSize: number }) => void;
    pageSize: number;
    ordersCount: number;
    page: number;
    handlePageChange?: (item: number) => void;
    handleItemsPerPageChange?: (item: number) => void;
}

export default function TablePagination(props: OwnProps) {
    const {
        handleMovePage,
        pageSize,
        ordersCount,
        page = 1,
        handlePageChange,
        handleItemsPerPageChange,
    } = props;
    const { classes } = useStyles();
    const pagesCount = Math.ceil(ordersCount / pageSize);
    const [rowCount, setRowCount] = useState(pageSize);

    const handlePage = (
        //eslint-disable-next-line
        //@ts-ignore
        event,
        value: number,
    ) => {
        handleMovePage({ page: value, pageSize: rowCount });
        handlePageChange && handlePageChange(value);
        handleItemsPerPageChange && handleItemsPerPageChange(rowCount);
    };
    const handleRowCount = (
        //eslint-disable-next-line
        //@ts-ignore
        event,
    ) => {
        const count = parseInt(event.target.value, 10);

        if (count < 0 || count > 200) {
            return;
        }

        if (count === 0) {
            setRowCount(1);
            return;
        }

        setRowCount(count);
        handleMovePage({ page: 1, pageSize: count });

        handlePageChange && handlePageChange(1);
        handleItemsPerPageChange && handleItemsPerPageChange(count);
    };

    return (
        <div className={classes.root}>
            <span className={classes.orders}>
                Showing {page === 1 ? 1 : (page - 1) * pageSize + 1}-
                {page * pageSize > ordersCount ? ordersCount : page * pageSize} of {ordersCount}:
            </span>

            <Grid className={classes.resultPerPageContainer}>
                <Typography className={classes.resultPerPageLabel}>Rows per page</Typography>
                <TextField
                    classes={{ root: classes.inputRoot }}
                    value={rowCount}
                    variant="outlined"
                    select
                    onChange={handleRowCount}>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                </TextField>
            </Grid>
            <Pagination
                count={pagesCount}
                showFirstButton
                showLastButton
                onChange={handlePage}
                page={page}
                shape="rounded"
                color="secondary"
                renderItem={(item) => {
                    return (
                        <PaginationItem
                            className={
                                item.selected
                                    ? `${classes.paginationItem} selected`
                                    : classes.paginationItem
                            }
                            {...item}
                        />
                    );
                }}
            />
        </div>
    );
}
const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: '24px',
        alignItems: 'center',
        margin: '16px',
    },

    orders: {
        fontSize: '12px',
        fontWeight: 500,
        color: theme.palette.neutral.neutral10,
        flex: 1,
    },
    paginationItem: {
        fontSize: 11,
        fontWeight: 'bold',
        color: theme.palette.neutral.neutral6,

        '&.selected': {
            textDecoration: 'none',
            fontSize: 14,
            color: theme.palette.neutralWhite,
        },
    },
    inputRoot: {
        height: '32px',
        maxWidth: '76px',
        cursor: 'pointer',

        '& .MuiOutlinedInput-root': {
            height: '32px',
            width: '76px',

            '& .MuiInputBase-input': {
                paddingRight: '16px',
            },
        },
    },
    resultPerPageContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
    },
    resultPerPageLabel: {
        fontSize: '14px',
        fontWeight: 500,
        color: theme.palette.neutral.neutral6,
    },
}));

import { ReactElement } from 'react';

import { Tooltip } from '@mui/material';

export interface HeaderTooltip {
    icon: ReactElement;
    text: string;
}
interface OwnProps {
    tooltip: HeaderTooltip;
}

export default function TableHeaderTooltip({ tooltip }: OwnProps) {
    return <Tooltip title={tooltip?.text}>{tooltip.icon}</Tooltip>;
}

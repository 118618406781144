import React from 'react';

import { Box } from '@mui/material';

import { LabelColor } from '../FormFields/GenericFormInput';
import InfoTooltip, { GenericTooltip } from '../Tooltip/InfoTooltip';
import FieldLabel from './FieldLabel';

interface OwnProps {
    label?: string;
    tooltip?: GenericTooltip;
    isRequired?: boolean;
    labelColor?: LabelColor;
}

export default function FieldLabelWithTooltip({
    label,
    tooltip,
    isRequired,
    labelColor,
}: OwnProps) {
    return (
        <Box display={'flex'} gap={1}>
            {label && <FieldLabel label={label} labelColor={labelColor} isRequired={isRequired} />}
            {tooltip && <InfoTooltip text={tooltip.text} position={tooltip.position} />}
        </Box>
    );
}

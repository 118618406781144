import { DistributiveOmit } from '../../interfaces';
import AddressFinderField, { AddressFinderInput } from './AddressFinderField';
import AutocompleteField, { Autocomplete } from './AutocompleteField';
import CheckboxRow, { Checkbox } from './CheckboxRow';
import DatePickerField, { DatePickerInput } from './DatePickerField';
import InputField, { Input } from './InputField';
import MaskedInputField, { MaskedInputProps } from './MaskedInputField';
import PhoneNumberInputField, { PhoneInput } from './PhoneNumberInput';
import RadiobuttonRow, { RadioRow } from './RadiobuttonRow';
import RangeSliderField, { RangeSlider } from './RangeSliderField';
import RichTextField, { RichText } from './RichTextField';
import SearchAutocompleteField, { SearchAutocomplete } from './SearchAutocompleteField';
import SelectField, { SelectInput } from './SelectField';
import SwitcherField, { Switcher } from './SwitcherField';
import TextareaField, { Textarea } from './TextareaField';
import UploadFileField, { UploadFile } from './UploadFileField';
import WeekDayPickerField, { WeekDayPicker } from './WeekDayPickerField';

export const genericComponents = {
    input: InputField,
    select: SelectField,
    textarea: TextareaField,
    fileupload: UploadFileField,
    datepicker: DatePickerField,
    radio: RadiobuttonRow,
    autocomplete: AutocompleteField,
    checkbox: CheckboxRow,
    maskedInput: MaskedInputField,
    switch: SwitcherField,
    richText: RichTextField,
    phoneInput: PhoneNumberInputField,
    range: RangeSliderField,
    searchAutocomplete: SearchAutocompleteField,
    addressFinder: AddressFinderField,
    weekDayPicker: WeekDayPickerField,
};

export type TypesOfComponent =
    | Input
    | SelectInput
    | Textarea
    | UploadFile
    | DatePickerInput
    | RadioRow
    | Checkbox
    | Autocomplete
    | (MaskedInputProps & Input)
    | Switcher
    | RichText
    | RangeSlider
    | PhoneInput
    | SearchAutocomplete
    | AddressFinderInput
    | WeekDayPicker;

export type ComponentForm =
    | 'input'
    | 'select'
    | 'textarea'
    | 'fileupload'
    | 'datepicker'
    | 'radio'
    | 'autocomplete'
    | 'checkbox'
    | 'maskedInput'
    | 'switch'
    | 'range'
    | 'richText'
    | 'phoneInput'
    | 'searchAutocomplete'
    | 'addressFinder'
    | 'weekDayPicker';

export type FieldScheme = 'quarter' | 'third' | 'half' | 'twoThird' | 'full';
export type DrivenTypeComponent = DistributiveOmit<TypesOfComponent, 'onChange'>;

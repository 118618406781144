import React from 'react';

import { Box } from '@mui/material';

import { ReactComponent as BuildingIcon } from '../../../assets/icons/building_icon.svg';
import { ReactComponent as EmailIcon } from '../../../assets/icons/communications_icon.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/icons/phone_icon.svg';
import AppTypography from '../../../design/AppTypography';
import LinkText from '../../../design/Fields/LinkText';
import { phoneTypeMapper } from '../../../pages/utils';
import { PhoneType } from '../../../reusable/FormUI/PhoneNumberField';
import InfoWithLabel from '../../../reusable/InfoWithLabel';
import { phoneMaskForString } from '../../../utils/fieldMasks';

interface OwnProps {
    title: string;
    email?: string;
    phone?: PhoneType[];
    address?: string;
}
export default function FarmContactInfo({ email, phone, address, title }: OwnProps) {
    return (
        <Box>
            <AppTypography
                text={title}
                variant={'font11'}
                fontWeight={'bold'}
                color={'text.neutral9'}
            />
            <InfoWithLabel
                icon={EmailIcon}
                text={
                    email ? (
                        <LinkText to={`mailto:${email}`} text={email} fontWeight={'normal'} />
                    ) : (
                        'N/A'
                    )
                }
            />
            {phone?.map((item) => {
                return (
                    <InfoWithLabel
                        key={item.type}
                        icon={PhoneIcon}
                        text={`${phoneTypeMapper[item?.type]}: ${phoneMaskForString(item?.number)}`}
                    />
                );
            })}
            <InfoWithLabel icon={BuildingIcon} text={address} />
        </Box>
    );
}

import React from 'react';

import { Box, Stack } from '@mui/material';

import { ReactComponent as BuildingIcon } from '../../../assets/icons/building_icon.svg';
import { ReactComponent as LabelIcon } from '../../../assets/icons/label_icon.svg';
import AppTypography from '../../../design/AppTypography';
import InfoWithLabel from '../../../reusable/InfoWithLabel';
import FarmContactInfo from './FarmContactInfo';
import { PrimaryFarmInfo, RemindersInfo } from './FarmOtherInfo';

interface OwnProps {
    primaryFarmInfo: PrimaryFarmInfo;
    remindersInfo: RemindersInfo;
    mailAddress: string;
}

export default function LeftColumnFarmInfo({
    primaryFarmInfo,
    remindersInfo,
    mailAddress,
}: OwnProps) {
    return (
        <Stack rowGap={3}>
            <FarmContactInfo
                title={'Primary'}
                email={primaryFarmInfo.primaryEmail}
                {...primaryFarmInfo}
            />
            <Box>
                <AppTypography
                    text={'Mailing'}
                    variant={'font11'}
                    fontWeight={'bold'}
                    color={'text.neutral9'}
                />
                <InfoWithLabel icon={BuildingIcon} text={mailAddress} />
            </Box>
            <Box>
                <AppTypography
                    text={'Reminders'}
                    variant={'font11'}
                    fontWeight={'bold'}
                    color={'text.neutral9'}
                />
                <InfoWithLabel
                    icon={LabelIcon}
                    text={`Last Outreach: ${remindersInfo?.lastOutreachDate}`}
                />
            </Box>
        </Stack>
    );
}

import React from 'react';
import { TreeNodeDatum } from 'react-d3-tree';

import ReusableTree from '../../../reusable/Tree/ReusableTree';
import FarmTeamCard from './FarmTeamCard';

export interface FarmTeamCard {
    id?: string;
    firstName: string;
    lastName: string;
    isRoot?: boolean;
    role?: string;
    isDisabled?: boolean;
    farmLogo: string;
    children?: FarmTeamCard[];
}
export type CombinedFarmNodeDatum = TreeNodeDatum & FarmTeamCard;

interface FarmTeamTreeProps {
    cardData: FarmTeamCard;
}

export default function FarmTeamTree({ cardData }: FarmTeamTreeProps) {
    return (
        <ReusableTree
            data={cardData}
            renderNode={(rd3tProps) => (
                <FarmTeamCard
                    {...rd3tProps}
                    nodeDatum={rd3tProps.nodeDatum as CombinedFarmNodeDatum}
                />
            )}
        />
    );
}

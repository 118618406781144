import { useState } from 'react';

export function useSorting(initialSortKey: string, initialSortOrder: 'asc' | 'desc') {
    const [sortKey, setSortKey] = useState<string>(initialSortKey);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>(initialSortOrder);

    const handleSort = (key?: string) => {
        if (key === sortKey) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            key && setSortKey(key);
            setSortOrder('asc');
        }
    };

    return { sortKey, sortOrder, handleSort };
}

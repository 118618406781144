import React from 'react';

import { Box, Typography } from '@mui/material';

import LinkText from '../../design/Fields/LinkText';
import StatusTag, { Tag } from '../../design/StatusTag/StatusTag';
import AvatarWithBorder from '../../features/ViewAllProfiles/PrimaryContactInfoPanel/AvatarWithBorder';
import { typeOfAdvocacy } from '../../features/ViewAllProfiles/PrimaryContactInfoPanel/PrimaryContactMainInfo';
import { SocialMedia } from '../../features/ViewLandownersDetails/LandPartnerDetails';
import { LinkedLandPartner } from '../../pages/AllProfiles/AllProfiles';
import PATHS from '../../router/paths';
import SocialMediaList from '../SocialMediaList';

export interface UserMain {
    id: string;
    landPartners: LinkedLandPartner[];
    name: string;
    avatar?: string;
    role?: string;
    isDeceased: boolean;
    advocacy?: typeOfAdvocacy[];
    isPrimaryContact?: boolean;
    socialMedia?: SocialMedia;
}

export default function UserMainInfo({
    name,
    role,
    avatar,
    advocacy,
    isDeceased,
    isPrimaryContact,
    landPartners,
    socialMedia,
}: UserMain) {
    return (
        <Box display={'flex'} alignItems={'flex-start'} justifyContent={'space-between'}>
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <AvatarWithBorder
                    isDeceased={isDeceased}
                    name={name}
                    avatar={avatar}
                    advocacy={advocacy}
                    isPrimaryContact={isPrimaryContact}
                />
                {isDeceased && <StatusTag status={'grey'} text={'Deceased'} size={'small'} />}
                {!isDeceased && (
                    <Box
                        alignItems={'center'}
                        display={'flex'}
                        justifyContent={'center'}
                        flexWrap={'wrap'}
                        gap={1}>
                        {advocacy?.map((i) => <StatusTag key={i} {...statusMap[i]} />)}
                    </Box>
                )}
            </Box>
            <Box
                display={'flex'}
                justifyContent={'space-between'}
                flexDirection={'column'}
                rowGap={3}
                p={3}>
                <Box display={'flex'} flexDirection={'column'}>
                    <Typography variant="font20" fontWeight="medium" color="text.secondary">
                        {name}
                    </Typography>
                    <Typography
                        textTransform={'capitalize'}
                        variant={'font14'}
                        fontWeight={'medium'}
                        color={'text.light'}>
                        {role}
                    </Typography>
                    {isPrimaryContact && (
                        <Typography
                            textTransform={'capitalize'}
                            variant={'font14'}
                            fontWeight={'medium'}
                            color={'text.light'}>
                            Primary Contact
                        </Typography>
                    )}
                    {landPartners.map(({ id: landPartnerId, name }) => (
                        <LinkText
                            key={landPartnerId}
                            to={
                                landPartnerId
                                    ? `/${PATHS.landpartners}/${PATHS.profiles}/${landPartnerId}`
                                    : ''
                            }
                            text={name}
                            variant={'font14'}
                        />
                    ))}
                </Box>
                <SocialMediaList socialMedia={socialMedia} />
            </Box>
        </Box>
    );
}
export const statusMap: Record<typeOfAdvocacy, Tag> = {
    BLOCKER: {
        text: 'Blocker',
        status: 'success',
        size: 'small',
    },
    CHAMPION: {
        text: 'Champion',
        status: 'success',
        size: 'small',
    },
    INFLUENCER: {
        text: 'Influencer',
        status: 'success',
        size: 'small',
    },
    KEY_DECISION_MAKER: {
        text: 'Key Decision Maker',
        status: 'success',
        size: 'small',
    },
    DO_NOT_CONTACT: {
        text: 'Do Not Contact',
        status: 'error',
        size: 'small',
    },
};

import React, { useContext } from 'react';

import { Grid } from '@mui/material';

import GroupByDropdown, { Option } from '../../../reusable/GroupByDropdown';
import { DocumentsType, DocumentTypeContext } from './DocumentTypeContext';

export default function GroupDocumentDropdown() {
    const { type, setType } = useContext(DocumentTypeContext);

    const handleChange = (value: string) => {
        setType(value as DocumentsType);
    };
    return (
        <Grid item alignItems={'center'} display={'flex'} columnGap={2} justifyContent={'flex-end'}>
            <GroupByDropdown options={typeList} option={type} handleChange={handleChange} />
        </Grid>
    );
}

const typeList: Option[] = [
    { id: 'JOB_DESCRIPTION', name: 'Job Descriptions' },
    { id: 'INSURANCE_POLICIES', name: 'Insurance Policies' },
    { id: 'FARM_VISION', name: 'Farm Vision' },
    { id: 'DISASTER_PLANNING', name: 'Disaster Planning' },
];

import MontserratLight from '../assets/fonts/Montserrat-Light.ttf';
import MontserratMedium from '../assets/fonts/Montserrat-Medium.ttf';
import MontserratRegular from '../assets/fonts/Montserrat-Regular.ttf';
import MontserratSemiBold from '../assets/fonts/Montserrat-SemiBold.ttf';

export default `
    @font-face {
        font-family: 'Montserrat';
        src: url(${MontserratLight}) format('trueType');
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
    }

    @font-face {
        font-family: 'Montserrat';
        src: url(${MontserratRegular}) format('trueType');
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
    }
    
    @font-face {
        font-family: 'Montserrat';
        src: url(${MontserratMedium}) format('trueType');
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
    }
    
    @font-face {
        font-family: 'Montserrat';
        src: url(${MontserratSemiBold}) format('trueType');
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
    }`;

import React from 'react';

import { Breadcrumbs as BreadcrumbsMUI } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import BreadcrumbLink, { BreadcrumbLinkProps } from './BreadcrumbLink';

interface BreadcrumbsProps {
    collapsed: boolean;
    links: BreadcrumbLinkProps[];
}
export default function Breadcrumbs({ collapsed, links }: BreadcrumbsProps) {
    const { classes } = useStyles();

    return (
        <BreadcrumbsMUI
            className={classes.breadcrumbs}
            separator="›"
            maxItems={collapsed ? 1 : links.length}
            aria-label="breadcrumb">
            {links.map((link, index) => (
                <BreadcrumbLink key={index} isLast={links.length === index + 1} {...link} />
            ))}
        </BreadcrumbsMUI>
    );
}

const useStyles = makeStyles()((theme) => ({
    breadcrumbs: {
        padding: theme.spacing(2),
    },
}));

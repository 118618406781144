import { Box } from '@mui/material';

import { useDocumentGroupsList } from './useDocumentGroupsList';

import DocumentGroupListItem from './DocumentGroupListItem';

export default function DocumentGroupList() {
    const { documentsGroup } = useDocumentGroupsList();

    return (
        <Box display={'flex'} flexDirection={'column'} rowGap={2}>
            {documentsGroup.map((group, index) => (
                <DocumentGroupListItem key={index} title={group.name} />
            ))}
        </Box>
    );
}

import React, { useEffect } from 'react';

import FilePreview from './FilePreview';

interface OwnProps {
    uploadedFiles: File[];
    onChange: (file: File[]) => void;
}
export default function FilesList({ uploadedFiles, onChange }: OwnProps) {
    useEffect(() => {
        onChange(uploadedFiles);
    }, [uploadedFiles]);

    function onRemoveFile(file: File) {
        const newFiles = uploadedFiles.filter((f) => f.name !== file.name);
        onChange(newFiles);
    }
    return (
        <div>
            {uploadedFiles.map((file, index) => (
                <FilePreview key={index} onRemoveFile={onRemoveFile} file={file} />
            ))}
        </div>
    );
}

import React from 'react';

import TextField from '@mui/material/TextField';

import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';

export interface Textarea {
    onChange: (value: string) => void;
    //eslint-disable-next-line
    onBlur?: (e: any) => void;
    value?: string;
    placeholder?: string;
    isDisabled?: boolean;
    errorText?: string;
    fixed?: number;
    name?: string;
    maxRows?: number;
    minRows?: number;
    resize?: 'none' | 'both' | 'horizontal' | 'vertical'; //TODO fix problem with 100% width on first page render
    isHidden?: boolean;
}

export default function TextareaField({
    onChange,
    onBlur,
    value,
    placeholder,
    isDisabled,
    errorText,
    fixed,
    resize,
    maxRows,
    minRows,
    isHidden,
    name,
}: Textarea) {
    const { classes } = useStyles();

    if (isHidden) return null;

    return (
        <TextField
            id={name}
            fullWidth
            onChange={(e) => onChange(e.target.value)}
            onBlur={onBlur}
            className={clsx(
                classes.inputWrapper,
                resize === 'none' && classes.fullWidth,
                resize === 'vertical' && classes.fullWidth,
            )}
            disabled={isDisabled}
            value={value}
            multiline
            maxRows={fixed || maxRows}
            minRows={fixed || minRows}
            error={!!errorText}
            helperText={errorText}
            placeholder={placeholder}
            inputProps={{
                style: {
                    fontSize: '12px',
                },
                className: clsx(
                    resize === 'both' && classes.textareaBoth,
                    resize === 'none' && classes.textareaNone,
                    resize === 'vertical' && classes.textareaVertical,
                    resize === 'horizontal' && classes.textareaHorizontal,
                ),
            }}
        />
    );
}

const useStyles = makeStyles()(() => ({
    inputWrapper: {
        maxWidth: '100%',
        width: 'fit-content',
    },
    fullWidth: {
        width: '100%',
    },
    textareaBoth: {
        resize: 'both',
    },
    textareaVertical: {
        resize: 'vertical',
    },
    textareaNone: {
        resize: 'none',
    },
    textareaHorizontal: {
        width: '100%',
        resize: 'horizontal',
    },
}));

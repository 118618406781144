import React from 'react';

import { Box } from '@mui/material';

import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';

import { ReactComponent as StarIcon } from '../../assets/icons/primary_star_icon.svg';
import UserAvatar from '../../design/Avatar/UserAvatar';

interface OwnProps {
    avatar: string;
    name: string;
    isMarked?: boolean;
}
export default function TreeCardAvatar({ avatar, name, isMarked }: OwnProps) {
    const { classes } = useStyles();
    return (
        <Box display={'flex'} alignItems={'baseline'}>
            <div className={clsx(classes.avatarContainer, isMarked && classes.markedNode)}>
                <UserAvatar avatar={avatar} name={name} />
            </div>
            {isMarked && (
                <div className={classes.starIcon}>
                    <StarIcon />
                </div>
            )}
        </Box>
    );
}
const useStyles = makeStyles()((theme) => ({
    avatarContainer: {
        position: 'relative',
        top: '20px',
        borderRadius: '50%',
        border: '6px solid #fff',
    },
    markedNode: {
        border: `3px solid ${theme.palette.statusBlue}`,
    },
    starIcon: {
        position: 'relative',
        left: '-6px',
    },
}));

import React, { useState } from 'react';

import { Button, Grid, Typography } from '@mui/material';

export interface WeekDayPicker {
    onChange: (value: string[]) => void;
    onBlur?: (e: any) => void; // eslint-disable-line
    value?: string[];
    isDisabled?: boolean;
    isValidationError?: boolean;
    errorText?: string;
    name?: string;
    size?: 'small' | 'medium';
    isHidden?: boolean;
    choiceType?: 'singleChoice' | 'multipleChoice';
}

const daysOfWeek = [
    { id: 'MONDAY', name: 'M' },
    { id: 'TUESDAY', name: 'T' },
    { id: 'WEDNESDAY', name: 'W' },
    { id: 'THURSDAY', name: 'T' },
    { id: 'FRIDAY', name: 'F' },
    { id: 'SATURDAY', name: 'S' },
    { id: 'SUNDAY', name: 'S' },
];

export default function WeekDayPickerField({
    onChange,
    value = [],
    isDisabled = false,
    isValidationError = false,
    errorText = '',
    name = 'day-picker',
    size = 'medium',
    isHidden = false,
    choiceType = 'singleChoice',
}: WeekDayPicker) {
    const [selectedDays, setSelectedDays] = useState<string[]>(value);

    if (isHidden) {
        return null;
    }

    const toggleDay = (day: { id: string; name: string }) => {
        if (isDisabled) return;

        let updatedDays;
        if (choiceType === 'singleChoice') {
            updatedDays = selectedDays.includes(day.id) ? [] : [day.id];
        } else {
            updatedDays = selectedDays.includes(day.id)
                ? selectedDays.filter((id) => id !== day.id)
                : [...selectedDays, day.id];
        }

        setSelectedDays(updatedDays);
        onChange(updatedDays);
    };

    return (
        <div>
            <Grid
                container
                spacing={1}
                justifyContent="center"
                role="group"
                sx={{
                    width: size === 'small' ? 200 : 400,
                }}
                aria-label="Day Picker">
                {daysOfWeek.map((day) => (
                    <Grid item key={day.id}>
                        <Button
                            id={`${name}-${day.id}`}
                            variant={selectedDays.includes(day.id) ? 'contained' : 'outlined'}
                            color={selectedDays.includes(day.id) ? 'primary' : 'secondary'}
                            onClick={() => toggleDay(day)}
                            disabled={isDisabled}
                            sx={{
                                minWidth: 40,
                                minHeight: 40,
                                borderRadius: '50%',
                                padding: 0,
                                margin: '0 4px',
                            }}>
                            {day.name}
                        </Button>
                    </Grid>
                ))}
            </Grid>
            {isValidationError && (
                <Typography variant="font11" color="text.error">
                    {errorText}
                </Typography>
            )}
        </div>
    );
}

import React from 'react';

import { Box } from '@mui/material';

import AnimatedSpinner from './AnimatedSpinner';

export default function FullScreenLoader() {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw',
            }}>
            <AnimatedSpinner />
        </Box>
    );
}
